import styled, { css } from 'styled-components';

export const container = css`
  max-width: 960px;
  padding: 0 30px;
  margin: auto;
`;

export const Container = styled.div`
  ${container}
`;
