import { Image } from '@/components/Image';
import { Link } from '@/components/Link';
import { graphql, useStaticQuery } from 'gatsby';

interface AppStoreStaticQuery {
  site: {
    siteMetadata: {
      appStoreUrl: string;
    };
  };
}

const AppStoreLink = (): JSX.Element => {
  const {
    site: {
      siteMetadata: { appStoreUrl },
    },
  } = useStaticQuery<AppStoreStaticQuery>(
    graphql`
      query {
        site {
          siteMetadata {
            appStoreUrl
          }
        }
      }
    `,
  );

  if (!appStoreUrl) {
    return <></>;
  }
  return (
    <Link
      href={appStoreUrl}
      target="_blank"
      title="Download Energy Level Tracker for iOS devices"
    >
      <Image
        src={`../../assets/images/apple-app-store-badge.png`}
        alt="Get on Appstore"
      />
    </Link>
  );
};

export default AppStoreLink;
