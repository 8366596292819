import styled from 'styled-components';
import media from 'styled-media-query';

export const Image = styled.img`
  width: calc(252px * 0.7);

  ${media.lessThan(`medium`)`
    width: calc(252px * 0.5);
  `}
`;
