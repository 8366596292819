import AppStoreLink from '@/components/AppStoreLink';
import { container, Container } from '@/components/Container';
import { Layout } from '@/components/Layout';
import PlayStoreLink from '@/components/PlayStoreLink';
import Screenshots from '@/components/Screenshots';
import SEO from '@/components/SEO';
import styled from 'styled-components';
import media from 'styled-media-query';
import LogoImage from '../internal-assets/images/logo.svg';

const IndexPage = (): JSX.Element => (
  <Layout>
    <SEO />
    <Header>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <AppInfo>
        <AppName>Energy Level Tracker</AppName>
        <AppDescription>
          Track your energy levels anytime of the day to determine when
          you&apos;re at your peak. Use this information to perform high energy
          tasks during this time frame.
        </AppDescription>
        <DesktopButtons>
          <PlayStoreLink />
          <AppStoreLink />
        </DesktopButtons>
      </AppInfo>
    </Header>
    <MobileButtons>
      <PlayStoreLink />
      <AppStoreLink />
    </MobileButtons>
    <ScreenShotsSection>
      <Container>
        <SectionTitle>Screenshots</SectionTitle>
      </Container>
      <Screenshots />
    </ScreenShotsSection>
    <DescriptionSection>
      <SectionTitle>Description</SectionTitle>
      <DescriptionContainer>
        <SectionParagraph>
          Find your flow and calculate your daily drive. Unlike a conventional
          diary, journal, or logger app, <strong>Energy Level Tracker</strong>
          {` `}
          quantifies your daily entries so you can optimize your schedule around
          your peak productivity, tackle your priorities at ideal times, and
          take breaks when needed. You can more effortlessly maximize
          efficiency, build momentum, and accomplish goals while avoiding
          burnout and stress.
        </SectionParagraph>
        <SectionParagraph>
          Schedule meetings, naps, projects, brainstorming, writing, reading,
          and more around your high and low energy cycles. The longer and more
          you track, the more you&apos;ll start to see your personalized energy
          patterns develop.
        </SectionParagraph>
        <SectionHeading>How It Works</SectionHeading>
        <SectionParagraph>
          A daily logger has never been more straightforward. Simply download
          and tap the plus button on the home screen to set your energy level
          for the current moment. Choose from a value of one to five, with one
          being the lowest amount of energy and five being the greatest. Come
          back to track multiple times during the day and night to explore more
          accurate data about your energy patterns.
        </SectionParagraph>
        <SectionHeading>Quantify</SectionHeading>
        <SectionParagraph>
          Find actionable data for real-world results. Energy Level Tracker
          provides an accurate overview based on the numerical values of your
          energy levels. View your analytics, including average energy levels,
          hourly trends, and more.
        </SectionParagraph>
        <SectionHeading>Privacy</SectionHeading>
        <SectionParagraph>
          Your privacy comes first. All of your information and activity is
          saved securely on your device and in no other location.
        </SectionParagraph>
      </DescriptionContainer>
    </DescriptionSection>
    <DescriptionSection>
      <SectionTitle>Health &amp; Wellness Benefits</SectionTitle>
      <DescriptionContainer>
        <SectionHeading>Identify Peak Productivity</SectionHeading>
        <SectionParagraph>
          Not all time is created equal. When your energy levels are low, it can
          take hours to complete a task, but when you&apos;re at your peak, the
          same task can take minutes! Schedule your critical to-dos, important
          meetings, or urgent work tasks when you&apos;re at your mental and
          physical best. Energy Level Tracker easily calculates hourly trends so
          you can identify your peak performance and take action.
        </SectionParagraph>
        <SectionHeading>Manage Mental Health</SectionHeading>
        <SectionParagraph>
          Burnout and depression from overworking ourselves, constantly busy
          schedules, or poor health decisions can leave us exhausted and more
          unproductive than ever. Having insight into our daily habits and
          energy levels can help us manage moods and take those much-needed
          mental breathers that give us more stability and peace of mind
          throughout the day.
        </SectionParagraph>
        <SectionHeading>Avoid Burnout</SectionHeading>
        <SectionParagraph>
          Being busy doesn&apos;t mean being productive. Constantly on the go or
          working too much can lead to mental and physical exhaustion that takes
          a serious toll on our health and results in a steep decrease in our
          productivity—even when we feel like we&apos;re still working
          excessively. Tracking your energy levels can help turn those results
          around with insights to optimize your schedule in the most meaningful
          and fulfilling way. You can use your time wisely without added stress
          while still achieving work goals and meeting life&apos;s demands.
        </SectionParagraph>
        <SectionHeading>Maximize Your Time</SectionHeading>
        <SectionParagraph>
          Align work, rest, and play around your natural energy rhythms to
          create a more balanced, healthful, and fulfilling day. With small
          changes, you can maximize your time and optimize your productivity.
          After tracking your energy levels, you may find it&apos;s best to
          finish important work first thing in the morning, take a walk during
          your mid-day slump, make time to be creative in the evening when
          energy levels rebound, and much more.
        </SectionParagraph>
      </DescriptionContainer>
    </DescriptionSection>
    <DescriptionSection>
      <SectionParagraph>
        Use your energy levels to your advantage. Download the app today to get
        started!
      </SectionParagraph>
    </DescriptionSection>
  </Layout>
);

export default IndexPage;

const Header = styled(Container)`
  display: flex;
  flex-direction: row;
  margin-top: 128px;
  margin-bottom: 128px;
  align-items: center;
  ${media.lessThan(`medium`)`
    align-items: flex-start;
    margin-top: 32px;
    margin-bottom: 16px;
  `}
`;

const LogoWrapper = styled.div`
  display: inline-block;
  background-size: contain;
  max-width: 250px;
  max-height: 250px;
  padding: 1px;
  margin-right: 64px;
  width: 100%;
  height: 100%;
  background-repeat: no-repeat;

  ${media.lessThan(`medium`)`
    max-width: 100px;
    max-height: 100px;
    margin-right: 16px;
`}
`;

const AppInfo = styled.div``;

const AppName = styled.h1`
  font-size: 42px;
  margin: 0;

  ${media.lessThan(`medium`)`
  font-size: 18px;
`}
`;

const AppDescription = styled.p`
  font-size: 24px;

  ${media.lessThan(`medium`)`
    font-size: 16px;
    margin-top: 8px;
    margin-bottom: 0;
`}
`;

const Buttons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;

  ${media.lessThan(`medium`)`
    margin-left: 0;
    margin-bottom: 64px;
  `}
`;

const DesktopButtons = styled(Buttons)`
  ${media.lessThan(`medium`)`
    display: none;
  `}
`;

const MobileButtons = styled(Buttons)`
  ${container}
  display: none;

  ${media.lessThan(`medium`)`
    display: flex;
  `}
`;

const Section = styled.section`
  margin-bottom: 16px;
  ${media.lessThan(`medium`)`
    margin-bottom: 8px;
  `}
`;

const SectionTitle = styled.h2`
  font-size: 24px;
  margin-top: 0;

  ${media.lessThan(`medium`)`
    font-size: 16px;
  `}
`;

const SectionHeading = styled.h3`
  font-size: 18px;
  margin-top: 0;
  text-transform: uppercase;

  ${media.lessThan(`medium`)`
    font-size: 12px;
  `}
`;

const ScreenShotsSection = styled(Section)``;

const DescriptionSection = styled(Section)`
  ${container}
`;

const DescriptionContainer = styled.div``;

const Logo = styled(LogoImage)`
  width: 100%;
  height: auto;
`;

const SectionParagraph = styled.p``;
