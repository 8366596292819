import { GatsbyImageData } from '@/models';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { Col, Container, Row } from 'styled-bootstrap-grid';
import styled from 'styled-components';

interface ScreenshotStaticQuery {
  screenshotOne: GatsbyImageData;
  screenshotTwo: GatsbyImageData;
  screenshotThree: GatsbyImageData;
  screenshotFour: GatsbyImageData;
}

const Screenshots = (): JSX.Element => {
  const { screenshotOne, screenshotTwo, screenshotThree, screenshotFour } =
    useStaticQuery<ScreenshotStaticQuery>(
      graphql`
        query {
          screenshotOne: file(
            relativePath: { eq: "1-energy-level-tracker-app-home-screen.png" }
          ) {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 1000)
            }
          }
          screenshotTwo: file(
            relativePath: {
              eq: "2-energy-level-tracker-app-set-energy-level-modal.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 1000)
            }
          }
          screenshotThree: file(
            relativePath: {
              eq: "3-energy-level-tracker-app-history-screen.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 1000)
            }
          }
          screenshotFour: file(
            relativePath: {
              eq: "4-energy-level-tracker-app-analytics-screen.png"
            }
          ) {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, width: 1000)
            }
          }
        }
      `,
    );

  const {
    childImageSharp: { gatsbyImageData: screenshotOneContent },
  } = screenshotOne;

  const {
    childImageSharp: { gatsbyImageData: screenshotTwoContent },
  } = screenshotTwo;

  const {
    childImageSharp: { gatsbyImageData: screenshotThreeContent },
  } = screenshotThree;

  const {
    childImageSharp: { gatsbyImageData: screenshotFourContent },
  } = screenshotFour;

  return (
    <ScreenShotsContainer>
      <ScreenShotList>
        <ScreenShotItem sm={6} lg={3}>
          <ScreenShot
            image={screenshotOneContent}
            alt="Screenshot of Energy Level Tracker App's Home Screen"
          />
        </ScreenShotItem>
        <ScreenShotItem sm={6} lg={3}>
          <ScreenShot
            image={screenshotTwoContent}
            alt="Screenshot of Energy Level Tracker App's Set Energy Level Modal"
          />
        </ScreenShotItem>
        <ScreenShotItem sm={6} lg={3}>
          <ScreenShot
            image={screenshotThreeContent}
            alt="Screenshot of Energy Level Tracker App's History Screen"
          />
        </ScreenShotItem>
        <ScreenShotItem sm={6} lg={3}>
          <ScreenShot
            image={screenshotFourContent}
            alt="Screenshot of Energy Level Tracker App's Analytics Screen"
          />
        </ScreenShotItem>
      </ScreenShotList>
    </ScreenShotsContainer>
  );
};

export default Screenshots;

const ScreenShotsContainer = styled(Container)``;

const ScreenShotList = styled(Row)``;

const ScreenShotItem = styled(Col)`
  padding-bottom: 2em;
`;

const ScreenShot = styled(GatsbyImage)`
  width: 100%;
  border-radius: 16px;
  box-shadow: 0 12px 48px rgba(17, 16, 62, 0.12);
  transition: all 0.3s ease-in-out;
  transform: scale(1);

  &:hover {
    box-shadow: 0 24px 48px rgba(17, 16, 62, 0.12);
    transform: scale(1.05);
  }
`;
