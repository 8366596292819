import { Image } from '@/components/Image';
import { Link } from '@/components/Link';
import { graphql, useStaticQuery } from 'gatsby';

interface PlayStoreStaticQuery {
  site: {
    siteMetadata: {
      playStoreUrl: string;
    };
  };
}

const PlayStoreLink = (): JSX.Element => {
  const {
    site: {
      siteMetadata: { playStoreUrl },
    },
  } = useStaticQuery<PlayStoreStaticQuery>(
    graphql`
      query {
        site {
          siteMetadata {
            playStoreUrl
          }
        }
      }
    `,
  );

  if (!playStoreUrl) {
    return <></>;
  }
  return (
    <Link
      href={playStoreUrl}
      target="_blank"
      title="Download Energy Level Tracker for Android devices"
    >
      <Image
        src={`../../assets/images/google-play-badge.png`}
        alt="Get on Google Play"
      />
    </Link>
  );
};

export default PlayStoreLink;
